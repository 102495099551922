import React, {useContext} from "react"
import { Link, graphql } from "gatsby"
//import { MDXRenderer } from "gatsby-plugin-mdx"
//import SEO from "../components/seo"
//import { rhythm, scale } from "../utils/typography"
import { LangugaeContext } from "../context/LanguageProvider";
import Layout from "../components/shared/Layout2"
import SEO from "../components/shared/seo"
import SectionTitle from "../components/shared/SectionTitle"
import Section from "../components/shared/Section"
import NavbarNew from "../components/shared/NavbarNew"
import SectionBreak from "../components/shared/SectionBreak"
import LightText from "../components/shared/LightText"
import Container from "../components/shared/Container"
import BigIcon from "../components/shared/BigIcon"
import MainButton from "../components/shared/MainButton"
class BlogPostTemplate extends React.Component {

  static contextType = LangugaeContext;

  render() {

    const svData = this.props.data.svBlog.edges
    const enData = this.props.data.enBlog.edges
    
    let post = null;

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      post = this.context.getCorrectPost(svData, enData, window)
    }
   /* let cmsData = svData;
    this.context.swedish ? null : cmsData = enData;

    let urlSplit = window.location.pathname.split("/")
    let lastSplit = urlSplit.pop()
    let secondLastSplit = urlSplit[urlSplit.length - 1]
   
    if(!lastSplit){
      lastSplit = secondLastSplit;
    }

    let thePost = null

    cmsData.map(post => {
      let rawSlug = post.node.fields.slug;
      let slug = rawSlug.replaceAll('/', '');

      if(slug === lastSplit){
        thePost = post.node.frontmatter;
      }
    }) */


    return (post &&
      <Layout>
            <NavbarNew darkMode={true} />
            <SEO title="Computer Vision" />
            <Section>
                <SectionBreak size={80} />

                <Container>
                    <SectionTitle text={post.title} />
                    
                    {post.blogImage?.childImageSharp?.fluid?.src.includes("null") ? 
                    null
                    : 
                    <BigIcon width="100%" iconUrl={post?.blogImage?.childImageSharp.fluid.src} />
                    }
                    {post?.textBlocks?.map(block => {
                        return <LightText boldText={block.boldText != '-' ? block.boldText + " " : null} text={block.text} />
                    })}
                </Container>

            {
            post.cta
            ? 
            <MainButton to={post.ff} text={post.cta} /> 
            : 
            null
            }
            </Section>
            

        </Layout >)
    
  }
}

export default BlogPostTemplate

export const pageQuery = graphql
`query {
    svBlog: allMdx(
      filter: {
        frontmatter: {templateKey: {eq: "sv-blog"}},
    }, 
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            blogImage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            textBlocks {
              text
            }
          }
        }
      }
    },

    enBlog: allMdx(
      filter: {
        frontmatter: {templateKey: {eq: "en-blog"}},
    }, 
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            
          }
        }
      }
    },

  }`


/*export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { 
      slug: { eq: $slug },
      next: {} 
    }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
*/